import React from "react"
import { graphql } from "gatsby"

import Bio from "../../components/bio"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

const Appearances = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="All posts" />
      <Bio />
      <div>
        <h3>Yehuda has appeared:</h3>
            <p>
            June 19th, 1997
            Reading and Short Talk
            The Integral Yoga Book Store
            7:00 PM
            227 West 13th Street
            New York, NY
            212 929-0586
            </p>
            <hr/>
            <p>June 22nd, 1997
            Book Signing
            Media Play
            1:00 - 3:00 PM
            88 Dunning Farm Road,  Middletown, NY  10940
            Located behind the Orange Plaza Mall in the Dunning Farm Shopping Center
            914-342-6955  ~ FAX: (914) 344-2092   </p>
            <hr/>
        <p>
            June 27th, 1997
            Yehudah Fine will be signing books at:
            Lauriat's Books Music & Cafe
            4:30 to 7:30 PM
            Galleria Mall
            Middletown, New York
            (914) 695-1355</p>
            <hr/>
        <p>July 27th, 1997
            Book Signing
            Media Play
            1:00 - 4:00 PM
            5047 Jonestown Road
            Harrisburg, Penn.
            Contact: Stewart Rhodes
            717-652-4200 
            </p>
            <hr/>

            <p>August 19th, 1997
            Jewish Family Service Seniors
            lunch and discussion group
            Temple Sinai
            Middletown, New York
            Contact: Donna Haviv
            914-341-1173
            </p>
            <hr/>

            <p>Sept. 25th, 1997
            Book Signing
            Barnes & Noble Superstore
            Thursday evening at 7PM
            University Village, Seattle Washington
            Contact: Gail Paul
            206-517-4107</p>
            <hr/>

            <p>September 27th, 1997
            Herzel Ner Tamid Synagogue
            Seattle, Washington
            Contact: Barbara Lahov
            206-323-8555 X207</p>
            <hr/>

            <p>September 28th, 1997
            Book Reading and Signing
            Tree of Life Bookstore
            2201 N.E. 65 St., Seattle, Washington
            206-527-1130</p>
            <hr/>

            <p>October 8th, 1997
            Live on AOL Jewish Chat
            Yehudah Fine will be answering Questions
            9:00 pm
            Don't forget: sign on and chat.....
            </p>
            <hr/>

            <p>October 26th, 1997
            Rochester Jewish Book Fair
            Jewish Community Center
            1200 Edgewood Avenue, Rochester, New York 14618
            Contact: Randi Morgenstern
            716-461-2000 x237</p>
            <hr/>

            <p>Nov. 4 & 5th, 1997
            Houston Jewish Book Festival
            Houston Jewish Community Center
            Houston, Texas
            Contact: Marilyn Hassid
            713-729-3200 x3228</p>
            <hr/>

            <p>Nov. 6th, 1997
            St. Louis Jewish Book Festival
            2 Millstone Campus Drive
            St. Louis, Missouri 63146
            Contact: Barbara Barnholtz
            314-432-5700</p>
            <hr/>

            <p>Nov. 9 & 10th, 1997
            Book Fair
            JCC of Greater Hartford
            355 Bloomfield Avenue
            West Hartford, Conn. 06117
            Coordinator: Marcia Krinsky
            860-236-4571 Ext. 350</p>
            <hr/>

            <p>Nov. 11th, 1997
            Toronto Jewish Book Festival
            Sponsoring Rabbi Fine
            National Council of Jewish Women
            Coordinator: Helen Retner
            416-636-1880</p>
            <hr/>

            <p>Nov. 12th, 1997
            Book Fair
            JCC of Greater New Haven
            360 Amity Road
            Woodbridge, Conn. 06525
            Contact: Allison Brook Spitzer
            203-387- 2JCC</p>
            <hr/>

            <p>Nov. 16th, 1997
            Yehudah Fine gave an address to parents
            and high school students
            Book Festival,  10:00 AM
            JCC of Buffalo, NY
            2640 North Forest Road
            Getzville, NY  14068</p>
            <hr/>

            <p>Dec. 8 & 9th, 1997
            Yehudah Fine will be giving a book reading and signing books
            Book Festival
            JCC of Greater Philadelphia
            10:00 AM
            610-896-7770</p>
            <hr/>
            
            <p>December 16th, 1997
            Yehudah Fine conducted a workshop titled:
            Of Course I Should Have Known Better, But I Didn't.
            8 Steps Towards Finding Hope when we Stumble
            The Integral Yoga Book Store
            7:00 - 9:00 PM
            227 West 13th Street
            New York, NY
            212 929-0586</p>
            <hr/>

            <p>December 17th
            Yehudah Fine will be Live on America Online (AOL)
            at the Addictions & Recovery Forum on this
            and every third Wednesday of each month
            at 9:00 p.m. Eastern Standard Time</p>
            <hr/>

            <p>December 26 & 27th, 1997
            Rabbi Fine will be the Keynote Speaker
            for the Western Regional Retreat Weekend
            NCSY- National Council of Synagogue Youth
            Los Angeles, Calafornia</p>
            <hr/>   
            <h2>1998</h2>
            <p>January 8th, 1998
            Rabbi Fine spoke and signed books at
            Barnes and Noble Bookstore
            7:30 PM
            Park Slope, Brooklyn, NY</p>
            <hr/>

            <p>January 31st, 1998
            "Kids that fall through the cracks"
            CONGREGATION BAIS TORAH
            89 WEST CARLTON ROAD
            SUFFERN NEW YORK 10901
            8.30pm
            914-352-1343</p>
            <hr/>

            <p>January 28th, 1998
            Rabbi Fine addressed parents and students
            Westchester Hebrew Day School
            New Rochelle, NY</p>
            <hr/>

            <p>February 10th, 1998
            Rabbi Fine will be addressing:
            The Yeshiva University Alumni Association
            Stern College for Women
            245 Lexington Avenue
            New York, NY  10016-4699
            (212) 340-7700</p>
            <hr/>  

            <p>March 24th, 1998
            Rabbi Fine appeared at various
            Washington, DC High Schools and after school programs</p>
            <hr/>

            <p>March 24th, 1998
            Rabbi Fine will be addressing:
            The Washington DC JCC - Book Fair
            1529 16th St. NW
            Washington, DC
            (202) 518-9400
            CONTACT: Rabbi Tamara Miller  
            </p>
            <hr/>

            <p>March 25th
            Rabbi Fine appeared at various
            Washington, DC High Schools and after school programs</p>
            <hr/>

            <p>April 1st
            Rabbi Fine appeared at various
            Indianapolis High Schools and after school programs</p>
            <hr/>

            <p>April 1st
            Rabbi Fine gave a talk and signed books at:
            BORDERS BOOKS, MUSIC AND CAFE
            5612 Castleton Lane
            Indianapolis, IN 46250
            (317) 849-8660
            CONTACT: Michael Reis</p>
            <hr/>

            <p>April 1st
            Rabbi Fine presented:
            Finding the Hope in Lost Kids' Lives: Maimonides' 8-Step Counseling Path to Meaning and Hope.
            American Counseling Association
            1998 World Conference
            Indianapolis, IN</p>
            <hr/>

            <p>April 2nd
            Rabbi Fine appeared at:
            BJE Hebrew High School
            317- 255- 3124
            Contact: Ora Leivant</p>
            <hr/>

            <p>April 28th
            Rabbi Fine appeared at:
            8 School Joint Program
            Temple Sinai
            1401 Limekiln Pike (& Dillon)
            Dresher, PA 19025
            (215) 643-6510  X 106
            Contact: Rabbi Howard Addison</p>
            <hr/>

            <p>May 3rd, 1998
            Rabbi Fine addressed:
            The Yeshiva University Alumni Association
            Fair Lawn, NJ</p>
           <hr/>
            <p>May 12th, 1998
            Rabbi Fine spoke at:
            Group Health Cooperative
            Teen Pregnancy and Parenting Clinic
            200 15th Avenue East (cwb-100)
            Seattle, Washington 98112</p>
            <hr/>

            <p>May 13th, 1998
            Rabbi Fine spoke at:
            Hebrew High School
            Mercer Island , Wash.</p>
            <hr/>

            <p>May 14th, 1998
            Rabbi Fine spoke at:
            Northwest Yeshiva High School
            Mercer Island, Washington</p>
            <hr/>

            <p>May 27th,1998
            Rabbi Fine addressed:
            The Yeshiva University Alumni Association
            Five Towns, New York</p>
            <hr/>

            <p>June 18th, 1998
            Rabbi Fine conducted a workshop titled:
            "Accepting our Imperfections"
            The Integral Yoga Book Store
            6:30 PM
            227 West 13th Street
            New York, NY 
            212 929-0586 </p>
            <hr/>
            <h2>1999</h2>
            <p>June 3, 1999
            Rabbi Fine gave a Keynote Address
            National Homeschooling Conference
            Ann Arbor, Michigan</p>
        </div>
    </Layout>
  )
}

export default Appearances

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`